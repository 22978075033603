import { computed, inject, Injectable, signal } from '@angular/core'
import { Participant } from '@participant/models/participant'
import { firstValueFrom } from 'rxjs'
import { ParticipantService, RegistrationsService } from 'superlikers-microsites-api'

@Injectable({
  providedIn: 'root'
})
export class UserService {
  participantService = inject(ParticipantService)
  registrationsService = inject(RegistrationsService)

  #userSignal = signal<Participant | null>(null)
  user = this.#userSignal.asReadonly()

  isLoggedIn = computed(() => this.user()?.authenticated ?? false)

  async getUser() {
    const response$ = this.participantService.getParticipant<Participant>()
    const response = await firstValueFrom(response$)

    this.#userSignal.set(response.participation)
  }

  async updateParticipant(data: Record<string, unknown>) {
    const response$ = this.registrationsService.updateParticipant(data)
    const response = await firstValueFrom(response$)

    return response
  }
}
